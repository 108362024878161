import './App.css';
import Sidebar from './components/Sidebar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Overview, {OverviewOne, OverviewThree, OverviewTwo} from './pages/Overview';
import { Reports } from './pages/Reports';
import Team from './pages/Team';
import Login from "./components/Login";
import {useState} from "react";
import Messages from "./pages/Messages";
import Products from "./pages/Products";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState('');


  const handleLogin = (username, password) => {
    // Assuming the username and password are 'admin'
    if (username === 'admin' && password === 'admin') {
      setIsLoggedIn(true);
      setError('');
    } else {
      setError('Մուտքագրված ծածկանունը կամ ծածկագիրը սխալ են');
    }
  };
  return (

    <Router>
      {isLoggedIn ? (
         <div>
           <Sidebar />
           <Switch>
             <Route path='/overview' exact component={Overview} />
             <Route path='/overview/users' exact component={OverviewOne} />
             <Route path='/overview/revenue' exact component={OverviewTwo} />
             <Route path='/overview/users1' exact component={OverviewThree} />
             <Route path='/reports' exact component={Reports} />
             <Route path='/products' exact component={Products} />
             <Route path='/team' exact component={Team} />
             <Route path='/messages' exact component={Messages} />
           </Switch>
         </div>
      ) : (
          <Login handleLogin={handleLogin} error={error} />
      )}

    </Router>
  );
}

export default App;
